

























import Vue from 'vue'
import { IPerson } from '../store/state';

// define computed properties for this component
interface ContactCardComputed {
	person: IPerson,
	color: string,
	translations: object
}

export default Vue.extend<{}, {}, ContactCardComputed, {}>({
	name:'contact-card',
	computed: {
		person () { return this.$store.getters.person },
		color () { return this.$store.getters.color },
		translations () { return this.$store.getters.translations }
	}
})
