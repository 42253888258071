import { Getter } from "vuex";
import { IState } from './state';

const person: Getter<IState, IState> = (state) => state.person;
const color: Getter<IState, IState> = (state) => state.color;
const translations: Getter<IState, IState> = (state) => state.translations;


export default {
	person,
	color,
	translations
}
