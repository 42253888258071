export interface IState {
	person: IPerson,
	color: string,
	translations: object
}

const deafultState: IState = {
	person: { name: '', pageUrl: '', imageUrl: '', mobile: '', phone: '', email: '', title: '', linkedin: '', instagram: '', twitter: ''},
	color: '',
	translations: {}
}

export interface IPerson {
	name: string,
	pageUrl: string,
	imageUrl: string,
	phone: string,
	mobile: string
	email: string,
	title: string,
	linkedin: string,
	instagram: string,
	twitter: string
}

export default deafultState;
